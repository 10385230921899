import React from "react"
import { Link } from "gatsby"

export default function Header(props) {
  return (
      <header id="main-header">
        <Link to="/"><h1>Yagmin Properties LLC</h1></Link>
        <nav>
            {/* <Link to="/sellers/">Sellers</Link> */}
            {/* <Link to="/available/">Available Properties</Link> */}
            {/* <Link to="/partners/">Partners</Link> */}
        </nav>
      </header>
  )
}
