import React from "react"
import Header from "./header"
import Footer from "./footer"

export default function Layout({ children }) {
  return (
    <div id="layout">
        <Header></Header>
            <div id="main-content">
                {children}
            </div>
        <Footer></Footer>
    </div>
  )
}